import React from 'react'
import { GlobeAltIcon, ScaleIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'WEB сервисы СКБ Контур',
    link: 'https://encrypta.kontur-partner.ru/',
    description:
      'Контур-Экстерн - подготовить и сдать отчетность в ФНС, СФР, Росстат... Диадок - обмениваться юридически значимыми электронными документооборота между компаниями (ЭДО). Получить электронную подпись для любых задач. И другие продукты СКБ Контур в городах Звенигород, Можайск, Руза и по всей России. ',
    icon: '',
    img: 'https://s.kontur.ru/common-v2/icons-products/kontur/avatar/kontur-avatar-512.png',
  },
  {
    name: 'Онлайн‑кассы и кассовое оборудование',
    link: 'https://encrypta.kontur-partner.ru/market',
    description:
      'Готовые комплекты кассового оборудования и программного обеспечения под ваши задачи. Подберем оборудование для нестандартных задач. Поключим и настроим программное обеспечение.',
    icon: '',
    img: 'https://s.kontur.ru/common-v2/icons-products/market-app/market-app-512.png',
  },
]

export function Home() {
  return (
    <div className="py-2 bg-gray-100">
      <div className="max-w-6xl mx-auto mb-14 px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="mt-20 text-base text-red-700 font-semibold tracking-wide uppercase">
            Добро пожаловать!
          </h2>
          <p className="mt-6 text-2xl leading-8 font-semibold tracking-tight text-gray-900 sm:text-3xl">
            В мир электронного документооборота
            <br />и электронных подписей.
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Делаем сложное понятным.
          </p>
        </div>

        <div className="mt-20 lg:ml-20">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-1 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md text-white">
                    <img className="h-12 w-12 rounded-md" src={feature.img} />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
                {feature.link && (
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    <a href={feature.link} target="_blank">
                      <button className=" hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-2xl shadow">
                        Подробнее...
                      </button>
                    </a>
                  </dd>
                )}
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
